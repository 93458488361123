<template>
    <div>
      <!-- 设计字典 -->
      <div>
        <a-button type="primary" style="margin-bottom: 10px" @click="add">新增 </a-button>
      </div>
      <div class="scroll-tabox">
        <a-table  class="AStockOutDetailTable" :columns="columnsAdd"  :dataSource="dataSource" :pagination="false" > 
          <span slot="score" slot-scope="text,record">
          {{ record.score }} 分
          </span>
          <!-- 开关 -->
            <span slot="is_Open" slot-scope="text,record">
            <a-switch
            v-model="record.isOpen!==0"
            checked-children="开"
            un-checked-children="关"
            @click="switchChange(record.isOpen === 1? 0 : 1,record)"
            default-checked
            />
          </span>
           <!-- 排序 -->
           <span slot="isort_index" slot-scope="text, record,index">
            <a @click="sortUp(record)"  v-show="index===0?false:true" style="margin-right:5px; font-weight:550">上移</a>
            <a @click="sortDown(record)" v-show="index===dataSource.length-1?false:true" style="font-weight:550">下移</a>
           </span>
           <!-- 操作 -->
           <span slot="is_operation" slot-scope="text, record,index">
            <a @click="edit(record,index)" style="margin-right:5px; font-weight:550">修改</a>
            <a-popconfirm
                 title="确定删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="del(record)"
                 @cancel="cancel"> 
            <a   style="font-weight:550">删除</a>
           </a-popconfirm>
           
           
           </span>
        </a-table>
    
        <!-- 新增对话框 -->
        <a-drawer
          :title="title"
          placement="right"
          :closable="true"
          :visible="visible"
          width="400px"
          @close="onClose()"
        >
        <a-form-model
            ref="ruleForm_add"
            :model="addForm"
            :rules="addRules">
        <a-form-model-item label="类型名称" prop="name">
            <a-input placeholder="请输入类型名称" v-model="addForm.name"/>
        </a-form-model-item>
        <a-form-model-item label="类型分值" prop="score">
            <a-input placeholder="请输入类型分值" v-model="addForm.score" />
        </a-form-model-item>
        </a-form-model>
        <div
            :style="{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }"
        >
          <a-button style="marginRight: 8px" @click="close">  
          取消
          </a-button>
          <a-button type="primary" @click="addSubmit">
            确认
          </a-button>
        </div>
      </a-drawer>
      </div>
    </div>
    </template>
    
    <script>
    import {InsertDesign, getAllDesign,EditDesign,DELDesign,UpMoveDesign, DownMoveDesign} from  '../../../service/dictionary'
    export default {
           name:'livedesigndictionary',
           data(){
            return{
            visible: false,
            dataSource:[],
            user:{},
            title:'',
            type:1,
            addForm:{
                id:'', //数据id
                name:'', //类型名
                score:'', //分值
                createdById:'', //创建人id
                isOpen:''//开关
            },
            columnsAdd:[
            {
              title: '类型名称',
              dataIndex: 'name',
              width: '15%',
              key:'name',
              align:'center'
            },
            {
              title: '类型分值',
              dataIndex: 'score',
              width: '15%',
              key:'score',
              scopedSlots: { customRender: 'score' },
              align:'center'
            
            },
            {
                title:'创建人',
                dataIndex:"author",
                width:'13%',
                key:'author',
                align:'center'
            },
            {
              title: '创建时间',
              dataIndex: 'createdTime',
              width: '25%',
              key:"createdTime",
              align:'center'
             
            },
            {
              title: '开关',
              dataIndex:'isOpen',
              width: '13%',
              key:'isOpen',
              ellipsis: true,
              scopedSlots: { customRender: 'is_Open' },
              align:'center'
            },
            {
                title:'排序',
                dataIndex:'sort',
                width:'15%',
                scopedSlots: { customRender: 'isort_index' },
                key:'sort',
                align:'left'
            },
            {
                title:'操作',
                dataIndex:'operation',
                width:'15%',
                scopedSlots: { customRender: 'is_operation' },
                key:'operation',
                align:'center'
            }
          ],
           addRules:{
             name: [{ required: true, message: '请输入', trigger: 'blur' },
             {
                    validator: function(rule, value, callback) {
                      if (/^[^\s]+[\s]*.*$/i.test(value) == false) {
                        callback(new Error("请输入正确的名称"));
                      } 
                      else {
                        //校验通过
                        callback();
                      }
                    },
                    trigger: "blur"
                  }
            ],
             score:[{ required: true, message: '请输入', trigger: 'blur' },
                    {
                    validator: function(rule, value, callback) {
                      if (/^[0-9]*$/i.test(value) == false) {
                        callback(new Error("请输入正确的分值"));
                      } 
                      else {
                        //校验通过
                        callback();
                      }
                    },
                    trigger: "blur"
                  }],
           },
          }},
          created(){
            this.user=localStorage.getItem("user_info") ? JSON.parse(localStorage.getItem("user_info")) : {}
            this.load()
          }, 
           methods: {
            // 获取数据
            load(){
            getAllDesign().then((res)=>{
               this.dataSource=res.data
            })
            },
            // 打开新增对话框
            add(){
              this.title='新增',
              this.type=1
              if(!this.user.id){
                this.$message.warning("请登录")
                return
               }
              this.addForm={createdById:this.user.id}
              this.visible= true
            },
            //添加
            addSubmit(type){
            if(this.type==1){
            this.$refs.ruleForm_add.validate(async valid=>{
              if(valid){
               let form=this.addForm
               let data={
                  name:form.name,
                  score:form.score,
                  createdById:form.createdById,
                  isOpen:0 //默认是0
                 }
                const response =await InsertDesign(data)
                if (response.code === 0) {
                 this.$message.success("添加成功")
                 this.visible=false
                 await this.load()
                }
                else{
                  this.$message.warning("添加失败")
               }
              }
             })
             } 
            // 修改
            else if (this.type=2){
              this.$refs.ruleForm_add.validate(async valid=>{
               if(valid){
                let form=this.addForm
                let data={
                  id:form.id,
                  name:form.name,
                  score:form.score,
                  createdById:form.createdById,
                  isOpen:form.isOpen
                 }
                const response =await EditDesign(data)
                if (response.code === 0) {
                    this.$message.success("修改成功")
                    this.visible=false
                    await this.load()
                  }
                  else{
                   this.$message.warning("修改失败")
                 }
              }
             })
             }
             },
            //  开关
           async  switchChange(a,record){
              let data={
                id:record.id,
                isOpen:a,
                name:record.name,
                score:record.score,
                createdById:this.addForm.createdById
              }
              // 调用修改函数
              const response=await EditDesign(data)
                if(response.code===0){
                  this.$message.success("修改成功")
                  await this.load()
                }
                else{
                   this.$message.warning("修改失败")
                 }
             },
            
           // 上移按钮
            async sortUp (record) {
               const response=await UpMoveDesign(record.id)
                if(response.code===0){
                  this.$message.success('移动成功')
                  await this.load()
                }
                else{
                  this.$message.warning('移动失败')
                }
             },
             // 下移按钮
            async sortDown(record){
              const response=await DownMoveDesign(record.id)
              if(response.code===0){
                this.$message.success('移动成功')
                await this.load()
              }
              else{
                this.$message.warning('移动失败')
              }
            },
            // 打开修改窗口
            edit(record){
              // let form={}
              // form=record,
              this.addForm.createdById=record.createdById
              this.addForm.id=record.id;
              this.addForm.name= record.name;
              this.addForm.score=record.score;
              this.visible=true
              this.title='修改'
              this.type=2
            },
            // 删除
           async del(record){
               const response =await DELDesign(record.id)
               if(response.data===1){
                  this.$message.success('删除成功')
                  await this.load()
               }
               else {
                this.$message.warning("删除失败")
               }
               },
           onClose() {
              this.visible = false;
              this.$refs.ruleForm_add.resetFields()
           },
           cancel(){
               this.$message.warning('取消删除');
            },
            close(){
            this.visible=false;
            this.$refs.ruleForm_add.resetFields()
         
            }
            }
            }
    
    </script>
    
<style scoped>
::v-deep .ant-table-thead > tr > th {
        font-size:15px;
        font-weight: 550;
      }
    </style>
